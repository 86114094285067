import 'bootstrap/dist/css/bootstrap.css'

import "@fortawesome/fontawesome-free/css/all.min.css";
import "landing/plugin/et-line/style.css";
import "landing/plugin/themify-icons/themify-icons.css";

import "flag-icon-css/css/flag-icon";

//import "landing/plugin/owl-carousel/css/owl.carousel.css";
import "landing/plugin/magnific/magnific-popup.css";
import "landing/style/master.scss";

import "jquery/dist/jquery.js"
import "landing/js/jquery-migrate-3.0.0.min.js"

//import "landing/plugin/appear/jquery.appear.js"
//import "landing/js/bootstrap/js/popper.min.js"
import "bootstrap/dist/js/bootstrap.bundle.js"


import "@fancyapps/fancybox/dist/jquery.fancybox.js"
import "@fancyapps/fancybox/dist/jquery.fancybox.min.css"
import "leaflet/dist/leaflet.js"
import "leaflet/dist/leaflet.css"

import "website/maps.js"
import "website/trip.js"

import "landing/js/custom.js"
//    %script{:src => "static/js/jquery-3.2.1.min.js"}
//    %script{:src => "static/js/jquery-migrate-3.0.0.min.js"}
//    %script{:src => "static/plugin/appear/jquery.appear.js"}
//    %script{:src => "static/plugin/bootstrap/js/popper.min.js"}
//    %script{:src => "static/plugin/bootstrap/js/bootstrap.js"}
//    %script{:src => "static/js/custom.js"}
//import "landing/js/app.js"

import "website/trip.css"