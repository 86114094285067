import MarkerImage from "./marker.png"

$(document).ready(function(){

  if ($("#trip-map").length > 0) {

    var map = L.map('trip-map');

    L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
        attribution: '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
    }).addTo(map);

    var destinationIcon = L.icon({
      iconUrl: MarkerImage,
      iconSize: [32, 37],
      iconAnchor: [16, 37],
      popupAnchor: [0, -28]
    });

    $.getJSON($("#trip-map").attr("data-map-url"), function(data) {
      var fg = L.geoJson(data, {
        pointToLayer: function (feature, latlng) {
          return L.marker(latlng, {icon: destinationIcon});
        }
      }).addTo(map);

      map.fitBounds(fg.getBounds());

    });

  }

});
